<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(16)
    const fontBody = ref(14)
    const frame = ref('')

    i18n.locale = 'en'
    const topic = ref(route.value.query.topic || '1')
    if (route.value.query.type == 'opd') {
      prints.printsCertSickOpd({
        opd_id: route.value.query.id,
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    } else {
      prints.printsCertSick({
        sick_id: route.value.query.id.split(','),
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    }

    // prints.printsCertSick({
    //   sick_id: route.value.query.id.split(','),
    //   lang: i18n.locale,
    // }).then(res => {
    //   dataTableList.value = res.orderdetail
    //   savePDF(res)
    // })

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a5',
      })
      doc.setProperties({
        title: `Medical Certificate ${data.length == 1 ? `${data[0].customer_id} ${data[0].customer_fullname} - ${data[0].opd_date}` : ''}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontBody.value)

      for (let i = 0; i < data.length; i++) {
        const spacer = 6
        let total = 12

        doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 10, 6, 28, 28)

        doc.setFontSize(fontTitle.value)
        doc.setFont('TH-Niramit', 'bold')
        if (topic.value == '2') {
          doc.text(`${i18n.t('checkPaper')}`, 40, total)
        } else {
          doc.text(`${i18n.t('medical_certificate')}`, 40, total)
        }
        doc.setFontSize(fontBody.value)
        doc.setFont('TH-Niramit', 'normal')
        doc.text(`${i18n.t('a_number')} ${data[i].sick_code}`, 200, total, null, null, 'right')
        total += spacer

        doc.text(`${data[i].shop_name} ${data[i].shop_nature}`, 40, total)
        total += spacer
        const text = `${data[i].shop_address} ${data[i].shop_district}  ${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`
        const text1 = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(text, 165)
        for (let x = 0; x < text1.length; x++) {
          let positionStarter = 0
          positionStarter = 40
          doc.text(positionStarter, total, text1[x])
          total += spacer * 2
        }
        doc.text(`${i18n.t('date')}...........${i18n.t('month')}....................${i18n.t('year')}................`, 200, total, null, null, 'right')
        doc.text(`${data[i].opd_date_d}`, 146, total - 0.5)
        doc.text(`${data[i].opd_date_m}`, 164, total - 0.5)
        doc.text(`${data[i].opd_date_y}`, 188, total - 0.5)
        total += spacer
        doc.text(`${i18n.t('ourself')}.................................................................................................... ${i18n.t('licensed_to_practice_medicine')} ${i18n.t('a_number')}........................`, 24, total)
        doc.text(`${data[i].user_fullname}`, 35, total - 0.5)
        doc.text(`${data[i].user_license}`, 180, total - 0.5)
        total += spacer

        doc.text(`${i18n.t('undergo_a_physical_examination')}............................................................................................            ${i18n.t('age')}........................${i18n.t('year')}`, 10, total)
        doc.text(`${data[i].customer_fullname}`, 52, total - 0.5)
        doc.text(`${data[i].customer_age}`, 157, total - 0.5)
        total += spacer
        if (data[i].sick_air_id == '1') {
          doc.addImage(require('./check.png'), 'JPEG', 125, total - 3.2, 3.5, 3.5)
        }
        doc.text(`${i18n.t('identification_card_number')}.........................................................................................            (  ) ${i18n.t('can_board')}`, 10, total)
        doc.text(`${data[i].customer_idcard}`, 36, total - 0.5)
        total += spacer * 2
        doc.text(`${i18n.t('symptom')}......................................................................................................................................................................................`, 24, total)

        // doc.text(`${data[i].sick_symptom}`, 39, total - 0.5)
        const sick_symptom = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].sick_symptom, 155)
        if (sick_symptom.length > 1) {
          for (let x = 0; x < sick_symptom.length; x++) {
            let positionStarter = 0
            if (x >= 1) {
              positionStarter = 25
              doc.text('....................................................................................................................................................................................................', 199.5, total, null, null, 'right')
            } else {
              positionStarter = 41
            }
            doc.text(positionStarter, total - 0.5, sick_symptom[x])

            total += spacer
          }
        } else {
          doc.text(`${data[i].sick_symptom}`, 41, total - 0.5)
          total += spacer
          doc.text('....................................................................................................................................................................................................', 199.5, total, null, null, 'right')
          total += spacer
        }
        doc.text(`${i18n.t('diagnose')}......................................................................................................................................................................................`, 24, total)
        const sick_diagnose = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].sick_diagnose, 155)
        if (sick_diagnose.length > 1) {
          for (let x = 0; x < sick_diagnose.length; x++) {
            let positionStarter = 0
            if (x >= 1) {
              positionStarter = 25
              doc.text('....................................................................................................................................................................................................', 199.5, total, null, null, 'right')
            } else {
              positionStarter = 41
            }
            doc.text(positionStarter, total - 0.5, sick_diagnose[x])
            total += spacer
          }
        } else {
          doc.text(`${data[i].sick_diagnose}`, 41, total - 0.5)
          total += spacer
          doc.text('....................................................................................................................................................................................................', 199.5, total, null, null, 'right')
          total += spacer
        }
        total += spacer
        doc.setFont('TH-Niramit', 'bold')
        doc.setFontSize(fontTitle.value)
        doc.text(`${i18n.t('have_an_opinion')}`, 10, total)
        total += spacer
        doc.setFont('TH-Niramit', 'normal')
        doc.setFontSize(fontBody.value)
        doc.addImage(require('./check.png'), 'JPEG', 25, total - 3.2, 3.5, 3.5)
        doc.text(`(  ) ${i18n.t('has_been_checked')}`, 24, total)
        doc.text(`${i18n.t('on_date')}...........${i18n.t('month')}......................${i18n.t('year')}................`, 70, total)
        doc.text(`${data[i].opd_date_d}`, 84, total - 0.5)
        doc.text(`${data[i].opd_date_m}`, 103.5, total - 0.5)
        doc.text(`${data[i].opd_date_y}`, 127, total - 0.5)
        total += spacer
        if (data[i].sick_day_amount > 0) {
          doc.addImage(require('./check.png'), 'JPEG', 25, total - 3.2, 3.5, 3.5)
        }
        doc.text(`(  ) ${i18n.t('should_be_rested_since')}`, 24, total)
        doc.text(`${i18n.t('date')}...........${i18n.t('month')}....................${i18n.t('year')}................ ${i18n.t('to')}`, 70, total)
        doc.text(`${data[i].sick_start_d || ''}`, 79, total - 0.5)
        doc.text(`${data[i].sick_start_m || ''}`, 96.5, total - 0.5)
        doc.text(`${data[i].sick_start_y || ''}`, 122, total - 0.5)
        total += spacer
        doc.text(`${i18n.t('date')}...........${i18n.t('month')}....................${i18n.t('year')}................ ${i18n.t('total')}.................${i18n.t('day')}`, 70, total)
        doc.text(`${data[i].sick_end_d || ''}`, 79, total - 0.5)
        doc.text(`${data[i].sick_end_m || ''}`, 96.5, total - 0.5)
        doc.text(`${data[i].sick_end_y || ''}`, 122, total - 0.5)
        doc.text(`${data[i].sick_day_amount || ''}`, 148, total - 0.5)
        total += spacer * 2

        doc.setFont('TH-Niramit', 'bold')
        doc.setFontSize(fontTitle.value)
        doc.text(`${i18n.t('patients_name')}`, 10, total)
        doc.text(`${i18n.t('reportpsychotropic2_name_13')}`, 115, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.setFontSize(fontBody.value)
        doc.text('...............................................................', 40, total)
        doc.text('...............................................................', 199.5, total, null, null, 'right')
        total += spacer
        doc.text(`(${data[i].customer_fullname})`, 67, total - 2, null, null, 'center')
        doc.text(`(${data[i].user_fullname})`, 172, total - 2, null, null, 'center')
        total += spacer
        doc.setFont('TH-Niramit', 'bold')
        doc.text(`${i18n.t('note')}`, 10, total)
        doc.setFont('TH-Niramit', 'normal')
        doc.text(`${i18n.t('medical_certificate_does_not_apply')}`, 20, total)
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
